import React from 'react'

import SEO from '../components/seo/seo'
import MainWrapper from '../components/ui/MainWrapper'
import ResetPassword from '../components/resetPassword'

const ResetPasswordPage = () => (
	<MainWrapper>
		<SEO title="Reset Password" keywords={['reset password', '']} />
		<ResetPassword />
	</MainWrapper>
)

export default ResetPasswordPage
