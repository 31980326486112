import * as React from 'react'
import { useEffect, useState } from 'react'
import { TextField, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { Link, navigate } from 'gatsby'
import { TransparentCard } from '../ui/TransparentCard'
import WaitButton from '../ui/WaitButton'
import { getTokenUserRole, getTokenUserId } from '../../utils/client'
import queryString from 'query-string'
import LogRocket from 'logrocket'
import { useResetPasswordMutation } from '../../apollo/generated'
import Swal from 'sweetalert2'
const logo = require('../../images/logo-white.png')

const ResetPassword = () => {
	const [email, setEmail] = useState('')
	const queryStrings =
		typeof window !== 'undefined' && queryString.parse(location.hash)
	const [_resetPassword, { loading }] = useResetPasswordMutation()
	const resetPassword = async () => {
		try {
			await _resetPassword({ variables: { email } })
			await Swal.fire(
				'Success',
				'Please check your email to complete the reset process'
			)
			navigate('/')
		} catch (e) {
			Swal.fire('An Error Occurred', e.message, 'error')
		}
	}

	useEffect(() => {
		if (getTokenUserRole() && getTokenUserId()) {
			if (process.env.GATSBY_LOGROCKET_ACTIVE === 'true') {
				LogRocket.identify(getTokenUserId())
			}
			if (queryStrings.job_id) {
				navigate(`/contractor/bidding-board/#job_id=${queryStrings.job_id}`)
			}
			navigate(
				getTokenUserRole() === 'HOMEOWNER' ? '/jobs' : '/contractor/jobs'
			)
		}
	}, [queryStrings.job_id])

	return (
		<Internal>
			<LogoAvatar src={logo} />
			<TransparentCard>
				<form
					onSubmit={e => {
						e.preventDefault()
						resetPassword()
					}}
				>
					<FormInput
						error={false}
						id="username"
						label="Email"
						onChange={e => setEmail(e.target.value)}
						margin="normal"
						variant="outlined"
						value={email}
						type="email"
						required
					/>
					<WaitButton inProgress={loading}>
						Reset Password
					</WaitButton>
				</form>
			</TransparentCard>
			<Typography variant="subtitle1" style={{ marginTop: 12, color: 'white' }}>
				Didn't mean to come here?{' '}
				<Link to={`/`} style={{ color: '#8B6137' }}>
					Back To Login
				</Link>
			</Typography>
		</Internal>
	)
}

const Internal = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	flex: 1;
	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
	animation: fadeIn 0.5s ease-out;

	> *:nth-child(2) {
		max-width: 92vw;
	}
`

const LogoAvatar = styled.img.attrs({ resizeMode: 'contain' })`
	width: 280px;
	max-width: 90vw;
	margin-bottom: 22px;
`

const FormInput = styled(TextField)`
	width: 100%;
`

export default ResetPassword
